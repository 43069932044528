import { render, staticRenderFns } from "./landing.vue?vue&type=template&id=11689b16&"
import script from "./landing.vue?vue&type=script&lang=js&"
export * from "./landing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/ProjectTemplate.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Flayouts%2Flanding.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsOrganismSidebar: require('/app/components/organisms/OrganismSidebar.vue').default,OrganismsOrganismMenuBarStatic: require('/app/components/organisms/OrganismMenuBarStatic.vue').default})
